import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Card, CardContent, Typography, Link } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const VenueDetail = () => {
  const location = useLocation();
  const venueDetails = location.state;

  if (!venueDetails) {
    return <div>No venue details available</div>;
  }

  // Placeholder image URL for the map preview
  const placeholderMapUrl = `https://via.placeholder.com/600x400?text=Map+Preview`;

  return (
    <Box
      sx={{
        padding: 2,
        maxWidth: 600,
        margin: "20px auto 20px 20px", // Adjust margin to move the box to the left
        overflow: "auto",
        maxHeight: "80vh",
        border: "1px solid #ccc",
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
        {venueDetails.name}
      </Typography>
      <Card>
        <CardContent>
          <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center" }}>
            <LocationOnIcon sx={{ marginRight: 1 }} />
            <Typography variant="h6" component="div">
              Dirección
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ marginLeft: 1 }}
            >
              {venueDetails.address}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="h6" component="div">
              Rating
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ marginLeft: 1 }}
            >
              {venueDetails.rating}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h6" component="div">
              Mapa
            </Typography>
            <Link
              href={venueDetails.locationURL}
              target="_blank"
              rel="noopener"
            >
              <img
                src={placeholderMapUrl}
                alt="Google Map"
                style={{ width: "100%", height: "auto", border: 0 }}
              />
            </Link>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default VenueDetail;
