import * as React from "react";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

export default function GameList({ gameList }) {
  const navigate = useNavigate();

  const handleGameClick = (game) => {
    navigate("/game-detail", { state: gameList[game] });
  };

  return (
    <Grid2 xs={6}>
      {gameList.map((game, index) => (
        <Box
          key={index}
          sx={{
            border: "1px solid gray",
            padding: "10px",
            marginBottom: "10px",
            cursor: "pointer", // copilot
          }}
          onClick={() => handleGameClick(index)}
        >
          <Grid2 container spacing={1}>
            <Grid2 xs={4}>
              <Typography variant="h4">
                {dayjs(game.dateAndTime).format("h:mm A")}
              </Typography>
            </Grid2>
            <Grid2 xs={8}>
              <Typography variant="h5">{game.venueName}</Typography>
              <Typography variant="body2">{game.venueAddress}</Typography>
            </Grid2>
            <Grid2 xs={8}>
              <Typography variant="body1">{game.pricePerPerson}</Typography>
              <Typography variant="body2">{game.teamSize}</Typography>
            </Grid2>
          </Grid2>
        </Box>
      ))}
    </Grid2>
  );
}
