import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useState, useEffect } from "react";
import HomePage from "./pages/HomePage";
import GamesPage from "./pages/GamesPage/GamesPage";
import VenuesPage from "./pages/VenuesPage/VenuesPage";
import PlayersPage from "./pages/PlayersPage";
import PaymentsPage from "./pages/PaymentsPage";
import GameDetail from "./components/GameDetail";
import VenueDetail from "./components/VenueDetail";
import { useAuth } from "react-oidc-context";
import { Stack } from "@mui/material";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "rgba(25, 118, 210, 1)",
  },
});

const StyledTab = styled((props) => (
  <Tab
    disableRipple
    {...props}
    sx={{
      height: "80px",
    }}
  />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: "rgba(25, 118, 210, 1)",
  },
}));

const NavigationTabs = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/home");
        break;
      case 1:
        navigate("/games");
        break;
      case 2:
        navigate("/venues");
        break;
      case 3:
        navigate("/players");
        break;
      case 4:
        navigate("/payments");
        break;
      default:
        navigate("/home");
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/home":
        setValue(0);
        break;
      case "/games":
        setValue(1);
        break;
      case "/venues":
        setValue(2);
        break;
      case "/players":
        setValue(3);
        break;
      case "/payments":
        setValue(4);
        break;
      default:
        setValue(0);
    }
  }, [location]);

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="start"
      alignItems="center"
      sx={{
        bgcolor: "rgba(25, 118, 210, 0.9)",
        height: "80px",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          direction: "row",
          alignItems: "center",
          paddingX: 3,
        }}
      >
        <img
          src="pichangas-logo-bg-removed.png"
          alt="Pichangas Logo"
          style={{
            height: "60%",
          }}
        />
      </Box>

      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
      >
        <StyledTab label="Inicio" />
        <StyledTab label="Pichangas" />
        <StyledTab label="Canchas" />
        <StyledTab label="Jugadores" />
        <StyledTab label="Pagos" />
      </StyledTabs>
    </Stack>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function App() {
  const auth = useAuth();

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Encountering error... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <BrowserRouter>
        <Box sx={{ width: "100%" }}>
          <NavigationTabs />
          <Routes>
            <Route path="/home" element={<HomePage />} />
            <Route path="/games" element={<GamesPage />} />
            <Route path="/venues" element={<VenuesPage />} />
            <Route path="/players" element={<PlayersPage />} />
            <Route path="/payments" element={<PaymentsPage />} />
            <Route path="/game-detail" element={<GameDetail />} />
            <Route path="/venue-detail" element={<VenueDetail />} />
            {/* Redirect to home page by default */}
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </Box>
      </BrowserRouter>
    );
  }

  auth.signinRedirect();
}
