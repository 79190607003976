import * as React from "react";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useForm, Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const options = [
  { value: "", label: "Cualquiera" },
  { value: 5, label: "5 jugadores por equipo" },
  { value: 6, label: "6 jugadores por equipo" },
  { value: 7, label: "7 jugadores por equipo" },
  { value: 8, label: "8 jugadores por equipo" },
  { value: 9, label: "9 jugadores por equipo" },
  { value: 10, label: "10 jugadores por equipo" },
  { value: 11, label: "11 jugadores por equipo" },
];

export default function Filters({ setFilters }) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      date: "",
      teamSize: "",
      triangular: "",
      twoHoursDuration: "",
      paymentMethod: "",
    },
  });

  const onSubmit = (data) => {
    setFilters(data);
  };

  return (
    <Box
      component="section"
      sx={{
        p: 2,
        border: "1px solid gray",
        borderRadius: 2,
        minWidth: "350px",
        maxWidth: "500px",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => <input type="date" {...field} />}
          />
          <Controller
            name="teamSize"
            control={control}
            render={({ field }) => (
              <Select {...field} fullWidth>
                {options.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Controller
            name="triangular"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label="Triangular"
              />
            )}
          />
          <Controller
            name="twoHoursDuration"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label="Duración de 2 horas"
              />
            )}
          />
          <Controller
            name="paymentMethod"
            control={control}
            render={({ field }) => (
              <FormControl {...field}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="YAPE"
                    control={<Radio />}
                    label="Yape"
                  />
                  <FormControlLabel
                    value="CASH"
                    control={<Radio />}
                    label="En efectivo"
                  />
                  <FormControlLabel
                    value="FREE"
                    control={<Radio />}
                    label="Gratis"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
          <Button type="submit" variant="outlined">
            Aplicar filtros
          </Button>
        </Stack>
      </form>
      <Stack spacing={2} sx={{ marginLeft: 2 }}></Stack>
    </Box>
  );
}
