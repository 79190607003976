import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export default function VenuesPage() {
  const navigate = useNavigate();
  const [venueList, setVenueList] = React.useState([]);

  const handleVenueClick = (venue) => {
    navigate("/venue-detail", { state: venue });
  };

  React.useEffect(() => {
    const fetchVenues = async () => {
      try {
        const response = await fetch(
          "https://24fdzb9nzi.execute-api.us-east-1.amazonaws.com/development/venues"
        );
        const data = await response.json();

        setVenueList(data);
      } catch (error) {
        console.error("Error fetching venues:", error);
      }
    };

    fetchVenues();
  }, []);

  return (
    <List sx={{ width: "100%", maxWidth: 600, bgcolor: "background.paper" }}>
      {venueList.map((venue, index) => {
        return (
          <React.Fragment key={index}>
            <ListItem onClick={() => handleVenueClick(venue)}>
              <ListItemText primary={venue.name} secondary={venue.address} />
              <Stack direction="row" spacing={2}>
                <Button variant="outlined" color="primary">
                  Follow
                </Button>
                <Typography variant="body2" color="text.secondary">
                  4767 followers
                </Typography>
              </Stack>
            </ListItem>
            <Divider />
          </React.Fragment>
        );
      })}
    </List>
  );
}
