import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Card, CardContent, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const GameDetail = () => {
  const location = useLocation();
  const gameDetails = location.state;

  if (!gameDetails) {
    return <div>No game details available</div>;
  }

  return (
    <Box
      sx={{
        padding: 2,
        maxWidth: 600,
        margin: "20px auto 20px 20px", // Adjust margin to move the box to the left
        overflow: "auto",
        maxHeight: "80vh",
        border: "1px solid #ccc",
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
        Cuando y donde
      </Typography>
      <Card>
        <CardContent>
          <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center" }}>
            <AccessTimeIcon sx={{ marginRight: 1 }} />
            <Typography variant="h6" component="div">
              Hora
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ marginLeft: 1 }}
            >
              {gameDetails.time}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center" }}>
            <LocationOnIcon sx={{ marginRight: 1 }} />
            <Typography variant="h6" component="div">
              Nombre
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ marginLeft: 1 }}
            >
              {gameDetails.venueName}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="h6" component="div">
              Dirección
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ marginLeft: 1 }}
            >
              {gameDetails.venueAddress}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="h6" component="div">
              Precio por persona
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ marginLeft: 1 }}
            >
              {gameDetails.pricePerPerson} PEN
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="h6" component="div">
              Tamaño del equipo
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ marginLeft: 1 }}
            >
              {gameDetails.teamSize}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default GameDetail;
