import * as React from "react";
import Stack from "@mui/material/Stack";
import Grid2 from "@mui/material/Grid2";
import Filters from "./Filters";
import GameList from "./GameList";
import { useEffect } from "react";
import axios from "axios";

export default function GamesPage() {
  const [filters, setFilters] = React.useState({
    date: "",
    teamSize: "",
    triangular: "",
    twoHoursDuration: "",
    paymentMethod: "",
  });
  const [queryParams, setQueryParams] = React.useState({});
  const [gameList, setGameList] = React.useState([]);

  useEffect(() => {
    const params = {};
    if (filters.date !== "") {
      params.date = filters.date;
    }
    if (filters.teamSize !== "") {
      params.teamSize = filters.teamSize;
    }
    if (filters.triangular !== "" && filters.triangular) {
      params.numberOfTeams = 3;
    }
    if (filters.twoHoursDuration !== "" && filters.twoHoursDuration) {
      params.duration = 2;
    }
    if (filters.paymentMethod !== "") {
      params.paymentMethod = filters.paymentMethod;
    }
    setQueryParams(params);
  }, [filters]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://24fdzb9nzi.execute-api.us-east-1.amazonaws.com/development/games",
          {
            params: queryParams,
          }
        );
        const fetchedGameList = response.data;

        if (Array.isArray(fetchedGameList)) {
          setGameList(fetchedGameList);
        }
      } catch (error) {
        console.error("Error fetching game list: ", error);
      }
    };

    fetchData();
  }, [queryParams]);

  return (
    <Stack
      direction={{ md: "row" }}
      spacing={{ xs: 1, sm: 1, md: 2 }}
      sx={{ p: 2, border: "3px solid white" }}
    >
      <Grid2 container spacing={2}>
        <Filters setFilters={setFilters} />
        <GameList gameList={gameList} />
      </Grid2>
    </Stack>
  );
}
